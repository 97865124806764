<template>
	<v-card class="project-edit-view">
		<v-card-title v-if="project.id">Edit Campaign</v-card-title>
		<v-card-title v-else>Create Campaign</v-card-title>
		<v-card-subtitle v-if="error" class="alert alert-error">{{
			error
		}}</v-card-subtitle>
		<div class="pa-6 pt-0">
			<form v-on:submit.prevent="submit">
				<ui-label>
					<ui-input
						v-model="project.title"
						placeholder="Title"
					></ui-input>
				</ui-label>
				<Roundup-Button
					color="primary"
					type="submit"
					:loading="isSaving"
					>{{ project.id ? 'Save' : 'Create' }}</Roundup-Button
				>
				<Roundup-Button
					type="danger"
					color="red"
					v-if="project.id"
					@click="remove"
					style="float:right"
					>Delete</Roundup-Button
				>
			</form>
		</div>
	</v-card>
</template>
<script>
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiToggle from '../../ui/Toggle'

export default {
	name: 'project-edit-view',
	inject: ['modalOptions'],
	components: { UiButton, UiLabel, UiInput, UiToggle },
	data() {
		return {
			project: cloneDeep(this.$store.state.projectDetail.item),
			isSaving: false,
			error: null
		}
	},
	methods: {
		...mapActions([
			'updateProject',
			'createProject',
			'deleteProject',
			'toggleArchive'
		]),
		submit() {
			this.project.id ? this.update() : this.create()
		},
		create() {
			this.isSaving = true
			this.createProject(this.project)
				.then(user => {
					this.isSaving = false
					this.$emit('close')
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		update() {
			this.isSaving = true
			this.updateProject(this.project)
				.then(() => {
					this.isSaving = false
					this.$emit('close')
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		remove() {
			if (
				confirm(
					'Are you sure you want to delete this project? This action cannot be undone.'
				)
			) {
				this.deleteProject(this.project).then(() => {
					this.$emit('close')
				})
			}

			return false
		}
	}
}
</script>

<style lang="scss"></style>

<template>
	<screen class="project-list-view" size="large">
		<div slot="title">
			<h1
				style="fontSize: 2.25rem !important; margin-top: 0px !important;"
			>
				Campaigns
			</h1>
		</div>
		<p slot="subtitle">
			Add campaigns if you want donors to choose a specific program or
			project to support.
		</p>
		<template slot="buttons">
			<Roundup-Button color="primary" @click="addProject"
				>Create Campaign</Roundup-Button
			>
		</template>

		<el-table
			slot="table"
			:data="items"
			@row-click="navigateToProjectEdit"
			style="width:100%"
		>
			<el-table-column prop="title" label="Title"> </el-table-column>
			<el-table-column prop="user_count" label="User Count" width="180">
			</el-table-column>
			<el-table-column prop="archived" label="Active">
				<template slot-scope="scope">
					<ui-toggle
						v-bind:style="{ flex: 1 }"
						@input="confirmArchive(scope.row)"
						:checked="!scope.row.archived"
					>
					</ui-toggle>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-if="items.length"
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>

		<v-dialog v-model="showProject" max-width="450px">
			<project-edit-view @close="showProject = false"></project-edit-view>
		</v-dialog>
	</screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { get, isEmpty } from '@/utilities'
import table from '@/mixins/table'

import UiButton from '../../ui/Button'
import Screen from '../../ui/Screen'
import Modal from '../../ui/Modal'
import UiToggle from '../../ui/Toggle'
import ProjectEditView from './ProjectEditView'

export default {
	name: 'project-list-view',
	components: { UiButton, Screen, Modal, ProjectEditView, UiToggle },
	mixins: [table],
	computed: {
		...mapState({
			items: ({ projectList }) => projectList.items,
			total: ({ projectList }) => projectList.items.length
		})
	},
	data() {
		return {
			showProject: false
		}
	},
	head: {
		title: {
			inner: 'Projects'
		}
	},
	methods: {
		...mapActions(['getProjects', 'toggleArchive']),
		paramsChanged(params) {
			this.getProjects(params)
		},
		addProject() {
			this.$store.commit('PROJECT_DETAIL', { id: null })
			this.showProject = true
		},
		navigateToProjectEdit(project) {
			this.$store.commit('PROJECT_DETAIL', project)
			this.showProject = true
		},
		confirmArchive(project) {
			this.showProject = false
			if (project.user_count > 0 && !project.archived) {
				if (
					confirm(
						'Are you sure you want to archive this project? The ' +
							project.user_count +
							' user(s) that have currently selected this project will be re-assigned to your general fund.'
					)
				) {
					this.toggle(project)
				}
				return false
			} else {
				this.toggle(project)
			}
		},
		toggle(project) {
			this.isSaving = true
			this.toggleArchive(project)
				.then(() => {
					this.isSaving = false
				})
				.catch(({ message }) => {
					this.isSaving = false
					this.error = message
				})

			return false
		},
		get,
		isEmpty
	},
	mounted() {
		this.getProjects(this.getParameters())
	}
}
</script>

<style lang="scss">
@import url('//unpkg.com/element-ui@2.13.2/lib/theme-chalk/index.css');

@import '../../../styles/variables';
</style>
